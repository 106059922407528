import React, { useEffect } from 'react';
import { logPageView, logEvent } from '../config/analytics.js';
import TyreSearchForm from '../components/search/TyreSearchForm.js';
import './HomePage.css';

const HomePage = () => {
    useEffect(() => {
        logPageView('/');
    }, []);

    const handleFeatureClick = (feature) => {
        logEvent('Home', 'Feature Click', feature);
    };

    const handleCallToAction = (action) => {
        logEvent('Home', 'CTA Click', action);
    };

    return (
        <div className="home-page">
            <section className="hero">
                <div className="container">
                    <div className="hero-content">
                        <h1>Find the Best Tyre Deals in the UK</h1>
                        <p>Compare prices from leading UK tyre retailers in seconds</p>
                        <TyreSearchForm />
                    </div>
                </div>
            </section>
            
            <section className="how-to-section">
                <div className="container">
                    <h2>How to Find Your Tyre Size</h2>
                    <div className="instruction-cards">
                        <div className="card">
                            <div className="card-body">
                                <h3>Check Your Tyre Sidewall</h3>
                                <p>Look for numbers like "205/55R16" on the side of your current tyres.</p>
                            </div>
                        </div>
                        
                        <div className="card">
                            <div className="card-body">
                                <h3>Vehicle Manual</h3>
                                <p>Your vehicle's manual will list the recommended tyre sizes.</p>
                            </div>
                        </div>
                        
                        <div className="card">
                            <div className="card-body">
                                <h3>Inside Driver's Door</h3>
                                <p>Many vehicles have a sticker inside the driver's door with tyre information.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="external-tools">
                <div className="container">
                    <h2>Need Help Finding Your Size?</h2>
                    <p>These websites can help you find your tyre size using your vehicle registration:</p>
                    <div className="tool-links">
                        <a href="https://www.blackcircles.com/tyres/find-by-registration" target="_blank" rel="noopener noreferrer" className="btn btn-outline-primary">Black Circles</a>
                        <a href="https://www.kwik-fit.com/tyres" target="_blank" rel="noopener noreferrer" className="btn btn-outline-primary">Kwik Fit</a>
                        <a href="https://www.national.co.uk/tyres" target="_blank" rel="noopener noreferrer" className="btn btn-outline-primary">National Tyres</a>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HomePage;