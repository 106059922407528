import React from 'react';
import './CookiePolicyPage.css';

function CookiePolicyPage() {
  return (
    <div className="cookie-policy-page">
      <div className="container">
        <h1>Cookie Policy</h1>
        <div className="policy-content">
          <section>
            <h2>Introduction</h2>
            <p>This Cookie Policy explains how UK Tyre Compare ("we", "us", or "our") uses cookies and similar technologies when you visit our website.</p>
          </section>

          <section>
            <h2>Types of Cookies We Use</h2>
            <h3>Essential Cookies</h3>
            <p>These cookies are necessary for the website to function and cannot be switched off:</p>
            <ul>
              <li>Cookie consent preferences</li>
              <li>Session management</li>
              <li>Basic website functionality</li>
            </ul>

            <h3>Analytics Cookies (Optional)</h3>
            <p>Help us understand how visitors interact with our website:</p>
            <ul>
              <li>Site usage patterns</li>
              <li>Performance monitoring</li>
              <li>User experience improvements</li>
            </ul>
          </section>

          <section>
            <h2>Your Cookie Choices</h2>
            <p>You have control over which cookies you accept:</p>
            <ul>
              <li>Use our cookie consent banner to manage preferences</li>
              <li>Adjust your browser settings to block or delete cookies</li>
              <li>Essential cookies cannot be disabled as they are required for basic site functionality</li>
            </ul>
          </section>

          <section>
            <h2>Changes to This Policy</h2>
            <p>We may update this cookie policy as our services evolve. Any changes will be posted on this page with an updated revision date.</p>
          </section>

          <section>
            <h2>Contact Us</h2>
            <p>If you have questions about our cookie policy, please contact us at support@tyre-compare.co.uk</p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicyPage;