import React, { useEffect } from 'react';
import { logPageView, logEvent } from '../config/analytics.js';
import './PrivacyPage.css';

const PrivacyPage = () => {
    useEffect(() => {
        logPageView('/privacy');
    }, []);

    const handleSectionView = (section) => {
        logEvent('Privacy', 'Section View', section);
    };

    return (
        <div className="privacy-page">
            <div className="container">
                <h1>Privacy Policy</h1>
                <div className="privacy-content">
                    <section onMouseEnter={() => handleSectionView('Information We Collect')}>
                        <h2>Information We Collect</h2>
                        <p>We only collect information that's necessary to provide our tyre comparison service:</p>
                        <ul>
                            <li>Tyre search preferences</li>
                            <li>Device and browser information</li>
                            <li>Website usage data</li>
                        </ul>
                    </section>

                    <section onMouseEnter={() => handleSectionView('How We Use Your Information')}>
                        <h2>How We Use Your Information</h2>
                        <p>Your information helps us:</p>
                        <ul>
                            <li>Show relevant tyre options</li>
                            <li>Improve our comparison service</li>
                            <li>Maintain site security</li>
                        </ul>
                    </section>

                    <section onMouseEnter={() => handleSectionView('Cookie Policy')}>
                        <h2>Cookie Policy</h2>
                        <p>We use essential cookies to provide basic site functionality and analytics cookies to understand how visitors use our site.</p>
                    </section>

                    <section onMouseEnter={() => handleSectionView('Your GDPR Rights')}>
                        <h2>Your GDPR Rights</h2>
                        <p>Under GDPR, you have the right to:</p>
                        <ul>
                            <li>Access your personal data</li>
                            <li>Correct inaccurate personal data</li>
                            <li>Request deletion of your personal data</li>
                            <li>Object to processing of your personal data</li>
                            <li>Data portability</li>
                            <li>Withdraw consent</li>
                        </ul>
                        <p>To exercise these rights, please contact us at support@tyre-compare.co.uk</p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPage;