import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './TyreCard.css';
import { logEvent } from '../../config/analytics.js';

const placeholderImage = '/images/tyre-placeholder.png';

function TyreCard({ tyre, isStarred, onStarClick }) {
  console.log('Tyre ratings debug:', {
    fuel: tyre.fuelEfficiency,
    grip: tyre.wetGrip,
    noise: tyre.noiseGrade, // Changed from noiseLevel
    fullTyre: tyre
  });

  const [imageError, setImageError] = useState(false);
  
  // Sort retailers by price (lowest first)
  const sortedRetailers = [...tyre.retailers].sort((a, b) => a.price - b.price);
  
  // Get top 3 deals
  const topDeals = sortedRetailers.slice(0, 3);
  const hasMoreDeals = sortedRetailers.length > 3;
  
  // Generate affiliate link
  const generateLink = (url, retailer) => {
    return url; // Affiliate URLs are now pre-generated
  };
  
  // Handle image loading error
  const handleImageError = (e) => {
    setImageError(true);
    console.log('Using placeholder image');
  };

  const handleStarClick = () => {
    onStarClick(tyre);
  };

  const handleRetailerClick = (retailer, price) => {
    logEvent('ecommerce', 'begin_checkout', {
        currency: 'GBP',
        value: price,
        items: [{
            item_id: tyre.id,
            item_name: tyre.model,
            item_brand: tyre.brand,
            item_category: 'Tyres',
            item_variant: `${tyre.width}/${tyre.profile}R${tyre.rim}`,
            price: price,
            quantity: 1
        }],
        retailer_name: retailer.name,
        retailer_id: retailer.id
    });
  };

  const handleCompareClick = () => {
    logEvent('Compare', 'Add', tyre.model);
  };

  return (
    <div className="tyre-card">
      <div className="mobile-header">
        <div className="tyre-thumbnail">
          <img 
            src={imageError ? placeholderImage : (tyre.image || placeholderImage)} 
            alt={`${tyre.brand} ${tyre.model}`}
            onError={handleImageError}
          />
        </div>
        
        <div className="mobile-basic-info">
          <div className="tyre">
            <h3>{tyre.brand} {tyre.model}</h3>
            <button 
              className={`shortlist-btn ${isStarred ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                handleStarClick();
              }}
              title={isStarred ? "Remove from comparison" : "Add to comparison"}
            >
              {isStarred ? '★' : '☆'}
            </button>
          </div>
          <p className="tyre-specs">
            {tyre.size} {tyre.speed} ({tyre.season})
          </p>
        </div>
      </div>

      <div className="tyre-content">
        <div className="tyre-thumbnail">
          <img 
            src={imageError ? placeholderImage : (tyre.image || placeholderImage)} 
            alt={`${tyre.brand} ${tyre.model}`}
            onError={handleImageError}
          />
        </div>
        <div className="tyre-info">
          <div className="tyre">
            <h3>{tyre.brand} {tyre.model}</h3>
            <button 
              className={`shortlist-btn ${isStarred ? 'active' : ''}`}
              onClick={(e) => {
                e.preventDefault();
                handleStarClick();
              }}
              title={isStarred ? "Remove from comparison" : "Add to comparison"}
            >
              {isStarred ? '★' : '☆'}
            </button>
          </div>
          
          <p className="tyre-specs">
            {tyre.size} {tyre.speed} ({tyre.season})
          </p>
          
          {tyre.fuelEfficiency && (
            <div className="tyre-ratings-compact">
              <div className="rating-item-compact">
                <span className={`rating-grade-compact grade-${tyre.fuelEfficiency}`}>
                  {tyre.fuelEfficiency}
                </span>
                <span className="rating-label-compact">FUEL</span>
              </div>
              <div className="rating-item-compact">
                <span className={`rating-grade-compact grade-${tyre.wetGrip}`}>
                  {tyre.wetGrip}
                </span>
                <span className="rating-label-compact">GRIP</span>
              </div>
              <div className="rating-item-compact">
                <span className={`rating-grade-compact grade-${tyre.noiseGrade}`}>
                  {tyre.noiseGrade}
                </span>
                <span className="rating-label-compact">NOISE</span>
              </div>
            </div>
          )}

          <Link to={`/tyre/${tyre.id}`} className="btn btn-outline-secondary btn-sm more-details-btn">
            More Details
          </Link>
        </div>
        
        <div className="retailer-deals">
          {topDeals.map((retailer, index) => (
            <div 
              key={retailer.name} 
              className={`retailer-deal ${index === 0 && retailer.price === Math.min(...sortedRetailers.map(r => r.price)) ? 'best-deal' : ''}`}
            >
              <p className="retailer">{retailer.name}</p>
              <p className="price">£{retailer.price.toFixed(2)}</p>
              <a 
                href={retailer.url}
                target="_blank" 
                rel="noopener noreferrer" 
                className="btn btn-success btn-sm"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRetailerClick(retailer, retailer.price);
                }}
              >
                View Deal
              </a>
            </div>
          ))}
        </div>
        
        {sortedRetailers.length > 3 && (
          <div className="more-deals">
            <Link to={`/tyre/${tyre.id}`} className="btn btn-outline-primary btn-sm">
              See {sortedRetailers.length - 3} more {sortedRetailers.length - 3 === 1 ? 'deal' : 'deals'}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

export default TyreCard;