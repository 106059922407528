import React, { useEffect } from 'react';
import { logPageView, logEvent } from '../config/analytics.js';
import './TermsPage.css';

const TermsPage = () => {
    useEffect(() => {
        logPageView('/terms');
    }, []);

    const handleSectionView = (section) => {
        logEvent('Terms', 'Section View', section);
    };

    return (
        <div className="terms-page">
            <div className="container">
                <h1>Terms of Use</h1>
                <div className="terms-content">
                    <section>
                        <h2>1. Service Description</h2>
                        <p>UK Tyre Compare provides a comparison service for tyre prices from various UK retailers. We do not sell tyres directly.</p>
                    </section>

                    <section>
                        <h2>2. Accuracy of Information</h2>
                        <p>While we strive to maintain accurate pricing and product information, final prices and availability are determined by our retail partners.</p>
                    </section>

                    <section>
                        <h2>3. User Responsibilities</h2>
                        <p>Users agree to:</p>
                        <ul>
                            <li>Provide accurate search information</li>
                            <li>Use the service for personal, non-commercial purposes</li>
                            <li>Not attempt to manipulate or misuse the service</li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default TermsPage;