import React, { useEffect, useRef } from 'react';
import './FilterPanel.css';
import { logEvent } from '../../config/analytics.js';
import { createFocusTrap, createListNavigation } from '../../utils/keyboardNav.js';

function FilterPanel({ filters, onFilterChange, tyres, toggleFilter, isMobile, onClose }) {
  const panelRef = useRef(null);
  const focusTrapRef = useRef(null);
  const listNavigationRef = useRef(null);

  const brands = [...new Set(tyres.map(tyre => tyre.brand))].sort();
  
  const prices = tyres
    .map(tyre => tyre.retailers?.length ? Math.min(...tyre.retailers.map(r => r.price)) : null)
    .filter(price => price !== null);

  const minPrice = Math.floor(Math.min(...prices, 0));
  const maxPrice = Math.ceil(Math.max(...prices));

  useEffect(() => {
    if (panelRef.current && isMobile) {
      focusTrapRef.current = createFocusTrap(panelRef.current, {
        onEscape: onClose,
        initialFocus: () => panelRef.current.querySelector('.close-filters')
      });

      if (isMobile) {
        focusTrapRef.current.activate();
      }

      return () => {
        if (focusTrapRef.current) {
          focusTrapRef.current.deactivate();
        }
      };
    }
  }, [isMobile, onClose]);

  useEffect(() => {
    const ratingGroups = panelRef.current?.querySelectorAll('.rating-filters');
    ratingGroups?.forEach(group => {
      const listNav = createListNavigation(group, {
        orientation: 'horizontal',
        wrap: true,
        onSelect: (element) => element.click()
      });
      listNav.activate();
      return () => listNav.deactivate();
    });
  }, []);

  const handleBrandChange = (e) => {
    const { value, checked } = e.target;
    const updatedBrands = checked
      ? [...filters.brand, value]
      : filters.brand.filter(brand => brand !== value);
    
    onFilterChange({
      ...filters,
      brand: updatedBrands
    });
  };

  const handleMaxPriceChange = (event) => {
    const value = event.target.value;
    onFilterChange({
      ...filters,
      maxPrice: value === '' ? null : parseFloat(value)
    });
  };

  const handleSeasonChange = (e) => {
    const { value, checked } = e.target;
    const updatedSeasons = checked
      ? [...filters.season, value]
      : filters.season.filter(season => season !== value);
    
    onFilterChange({
      ...filters,
      season: updatedSeasons
    });
  };

  const handleRatingChange = (type, value) => {
    if (type === 'noise') {
      const updatedValue = filters[type].includes(value) ? [] : [value];
      onFilterChange({
        ...filters,
        [type]: updatedValue
      });
    } else {
      const ratings = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
      if (filters[type].includes(value)) {
        onFilterChange({
          ...filters,
          [type]: []
        });
      } else {
        onFilterChange({
          ...filters,
          [type]: [value]
        });
      }
    }
    
    logEvent('Filter', 'Change', `${type}: ${value}`);
  };

  return (
    <aside 
      ref={panelRef}
      className="filter-panel"
      role="complementary"
      aria-label="Filter options"
    >
      <div className="filter-header">
        <h2 className="filter-title">Filters</h2>
        {isMobile && (
          <button 
            className="close-filters"
            onClick={onClose}
            aria-label="Close filters panel"
          >
            <svg viewBox="0 0 24 24" width="24" height="24" aria-hidden="true">
              <path fill="currentColor" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            </svg>
          </button>
        )}
      </div>

      <div className="filter-content">
        <section className="filter-section" aria-labelledby="brand-filter-heading">
          <h3 id="brand-filter-heading">Brand</h3>
          <div role="group" aria-labelledby="brand-filter-heading">
            {brands.map(brand => (
              <div className="form-check" key={brand}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`brand-${brand}`}
                  value={brand}
                  checked={filters.brand.includes(brand)}
                  onChange={handleBrandChange}
                />
                <label className="form-check-label" htmlFor={`brand-${brand}`}>
                  {brand}
                </label>
              </div>
            ))}
          </div>
        </section>
        
        <section className="filter-section" aria-labelledby="price-filter-heading">
          <h3 id="price-filter-heading">Maximum Price</h3>
          <div className="max-price-input">
            <span className="currency-symbol" aria-hidden="true">£</span>
            <input
              type="number"
              min="0"
              max={maxPrice || undefined}
              value={filters.maxPrice || ''}
              onChange={handleMaxPriceChange}
              className="form-control"
              aria-label="Maximum price in pounds"
              placeholder="Enter max price"
            />
          </div>
        </section>
        
        <section className="filter-section" aria-labelledby="season-filter-heading">
          <h3 id="season-filter-heading">Season</h3>
          <div role="group" aria-labelledby="season-filter-heading">
            {['Summer', 'Winter', 'All-Season'].map(season => (
              <div className="form-check" key={season}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`season-${season.toLowerCase()}`}
                  value={season}
                  checked={filters.season.includes(season)}
                  onChange={handleSeasonChange}
                />
                <label className="form-check-label" htmlFor={`season-${season.toLowerCase()}`}>
                  {season}
                </label>
              </div>
            ))}
          </div>
        </section>

        <section className="filter-section" aria-labelledby="fuel-filter-heading">
          <h3 id="fuel-filter-heading">Minimum Fuel Efficiency Rating</h3>
          <div 
            className="rating-filters"
            role="group"
            aria-label="Fuel efficiency ratings from A (most efficient) to G (least efficient)"
          >
            {['A', 'B', 'C', 'D', 'E', 'F', 'G'].map(rating => (
              <button 
                key={rating}
                className={`rating-button ${filters.fuel.includes(rating) ? 'selected' : ''}`}
                value={rating}
                onClick={() => handleRatingChange('fuel', rating)}
                aria-pressed={filters.fuel.includes(rating)}
                aria-label={`Minimum fuel efficiency rating: ${rating}`}
              >
                <span aria-hidden="true">{rating}</span>
              </button>
            ))}
          </div>
        </section>

        <section className="filter-section" aria-labelledby="grip-filter-heading">
          <h3 id="grip-filter-heading">Minimum Wet Grip Rating</h3>
          <div 
            className="rating-filters"
            role="group"
            aria-label="Wet grip ratings from A (best grip) to G (worst grip)"
          >
            {['A', 'B', 'C', 'D', 'E', 'F', 'G'].map(rating => (
              <button 
                key={rating}
                className={`rating-button ${filters.grip.includes(rating) ? 'selected' : ''}`}
                value={rating}
                onClick={() => handleRatingChange('grip', rating)}
                aria-pressed={filters.grip.includes(rating)}
                aria-label={`Minimum wet grip rating: ${rating}`}
              >
                <span aria-hidden="true">{rating}</span>
              </button>
            ))}
          </div>
        </section>

        <section className="filter-section" aria-labelledby="noise-filter-heading">
          <h3 id="noise-filter-heading">Noise Rating</h3>
          <div 
            className="rating-filters"
            role="group"
            aria-label="Noise ratings from A (quietest) to C (loudest)"
          >
            {['A', 'B', 'C'].map(rating => (
              <button 
                key={rating}
                className={`rating-button ${filters.noise.includes(rating) ? 'selected' : ''}`}
                value={rating}
                onClick={() => handleRatingChange('noise', rating)}
                aria-pressed={filters.noise.includes(rating)}
                aria-label={`Noise rating: ${rating}`}
              >
                <span aria-hidden="true">{rating}</span>
              </button>
            ))}
          </div>
          <p className="text-muted" id="noise-description">A = Quietest, C = Loudest</p>
        </section>
      </div>

      {isMobile && (
        <div className="filter-footer">
          <button 
            className="apply-filters"
            onClick={onClose}
            aria-label="Apply filters and close panel"
          >
            Apply Filters
          </button>
        </div>
      )}
    </aside>
  );
}

export default FilterPanel;