import React, { useEffect, useRef } from 'react';
import './ComparisonSidebar.css';
import { logEvent } from '../../config/analytics.js';
import { createFocusTrap, createListNavigation } from '../../utils/keyboardNav.js';

const ComparisonSidebar = ({ starredTyres, removeFromStarred, isExpanded, onClose, isMobile }) => {
  const sidebarRef = useRef(null);
  const focusTrapRef = useRef(null);
  const listNavigationRef = useRef(null);

  const getBestPrice = (tyre) => {
    if (!tyre.retailers || tyre.retailers.length === 0) {
      return 'N/A';
    }
    const lowestPrice = Math.min(...tyre.retailers.map(r => r.price));
    return lowestPrice.toFixed(2);
  };

  const handleRemoveTyre = (tyre) => {
    logEvent('Compare', 'Remove', tyre.model);
    removeFromStarred(tyre.id);
    
    if (starredTyres.length === 1) {
      onClose();
    }
  };

  const handleKeyPress = (e, tyre) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleRemoveTyre(tyre);
    }
  };

  useEffect(() => {
    if (sidebarRef.current && isExpanded) {
      focusTrapRef.current = createFocusTrap(sidebarRef.current, {
        onEscape: onClose,
        initialFocus: () => sidebarRef.current.querySelector(isMobile ? '.close-comparison' : '.sidebar-toggle')
      });

      focusTrapRef.current.activate();

      return () => {
        if (focusTrapRef.current) {
          focusTrapRef.current.deactivate();
        }
      };
    }
  }, [isExpanded, onClose, isMobile]);

  useEffect(() => {
    if (sidebarRef.current) {
      const tyresList = sidebarRef.current.querySelector('.starred-tyres-list');
      if (tyresList && starredTyres.length > 0) {
        listNavigationRef.current = createListNavigation(tyresList, {
          orientation: 'vertical',
          wrap: true
        });
        listNavigationRef.current.activate();

        return () => {
          if (listNavigationRef.current) {
            listNavigationRef.current.deactivate();
          }
        };
      }
    }
  }, [starredTyres.length]);

  return (
    <aside 
      ref={sidebarRef}
      className={`comparison-sidebar ${isExpanded ? 'expanded' : 'collapsed'} ${isMobile ? 'mobile' : ''}`}
      role="complementary"
      aria-label="Tyre comparison panel"
      aria-expanded={isExpanded}
    >
      <div className="sidebar-content">
        <div className="sidebar-header">
          <h2 id="comparison-title">Compare Tyres ({starredTyres.length}/3)</h2>
          {!isMobile && (
            <button 
              className="sidebar-toggle"
              onClick={onClose}
              aria-label={isExpanded ? "Collapse comparison panel" : "Expand comparison panel"}
              aria-expanded={isExpanded}
            >
              <span aria-hidden="true">{isExpanded ? '>' : '<'}</span>
            </button>
          )}
          {isMobile && (
            <button 
              className="close-comparison"
              onClick={onClose}
              aria-label="Close comparison panel"
            >
              <svg viewBox="0 0 24 24" width="24" height="24" aria-hidden="true">
                <path fill="currentColor" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
              </svg>
            </button>
          )}
        </div>
        
        {starredTyres.length === 0 ? (
          <p className="empty-message" role="status">Star up to 3 tyres to compare them</p>
        ) : (
          <div 
            className="starred-tyres-list"
            role="list"
            aria-label="Tyres selected for comparison"
          >
            {starredTyres.map(tyre => (
              <div 
                key={tyre.id} 
                className="starred-tyre-card"
                role="listitem"
                tabIndex="0"
              >
                <button 
                  className="remove-button"
                  onClick={() => handleRemoveTyre(tyre)}
                  onKeyPress={(e) => handleKeyPress(e, tyre)}
                  aria-label={`Remove ${tyre.brand} ${tyre.model} from comparison`}
                >
                  <span aria-hidden="true">×</span>
                </button>
                <div className="starred-tyre-info">
                  <h3>{tyre.brand} {tyre.model}</h3>
                  <div 
                    className="tyre-ratings-compact"
                    role="group"
                    aria-label={`Performance ratings for ${tyre.brand} ${tyre.model}`}
                  >
                    <div className="rating-item-compact">
                      <span 
                        className={`rating-grade-compact grade-${tyre.fuelEfficiency}`}
                        role="img" 
                        aria-label={`Fuel efficiency rating: ${tyre.fuelEfficiency}`}
                      >
                        {tyre.fuelEfficiency}
                      </span>
                      <span className="rating-label-compact">FUEL</span>
                    </div>
                    <div className="rating-item-compact">
                      <span 
                        className={`rating-grade-compact grade-${tyre.wetGrip}`}
                        role="img" 
                        aria-label={`Wet grip rating: ${tyre.wetGrip}`}
                      >
                        {tyre.wetGrip}
                      </span>
                      <span className="rating-label-compact">GRIP</span>
                    </div>
                    <div className="rating-item-compact">
                      <span 
                        className={`rating-grade-compact grade-${tyre.noiseGrade}`}
                        role="img" 
                        aria-label={`Noise level rating: ${tyre.noiseGrade}`}
                      >
                        {tyre.noiseGrade}
                      </span>
                      <span className="rating-label-compact">NOISE</span>
                    </div>
                  </div>
                  <div 
                    className="best-price"
                    aria-live="polite"
                  >
                    <span className="sr-only">Best price:</span> £{getBestPrice(tyre)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </aside>
  );
};

export default ComparisonSidebar;