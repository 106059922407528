import React from 'react';
import TyreCard from './TyreCard.js';
import './TyreList.css';

function TyreList({ tyres, starredTyres, onStarTyre }) {
  return (
    <section 
      className="tyre-list"
      aria-label="Tyre search results"
      role="region"
    >
      {tyres.length === 0 ? (
        <div className="no-results" role="status">
          <h2>No tyres found</h2>
          <p>Try adjusting your search criteria</p>
        </div>
      ) : (
        <div className="results-count" role="status" aria-live="polite">
          {tyres.length} {tyres.length === 1 ? 'tyre' : 'tyres'} found
        </div>
      )}
      <div role="list">
        {tyres.map(tyre => (
          <div role="listitem" key={tyre.id}>
            <TyreCard 
              tyre={tyre}
              isStarred={starredTyres.some(starred => starred.id === tyre.id)}
              onStarClick={onStarTyre}
            />
          </div>
        ))}
      </div>
    </section>
  );
}

export default TyreList;