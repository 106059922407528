import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/common/Header.js';
import Footer from './components/common/Footer.js';
import HomePage from './pages/HomePage.js';
import ResultsPage from './pages/ResultsPage.js';
import TyreDetailPage from './pages/TyreDetailPage.js';
import AboutPage from './pages/AboutPage.js';
import FaqPage from './pages/FaqPage.js';
import ContactPage from './pages/ContactPage.js';
import PrivacyPage from './pages/PrivacyPage.js';
import TermsPage from './pages/TermsPage.js';
import AffiliateDisclosurePage from './pages/AffiliateDisclosurePage.js';
import CookiePolicyPage from './pages/CookiePolicyPage.js';
import CookieConsent from './components/common/CookieConsent.js';
import ScrollToTop from './components/common/ScrollToTop.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { CookieProvider } from './context/CookieContext.js';
import { initGA, logPageView } from './config/analytics.js';

function App() {
  useEffect(() => {
    try {
      initGA();
    } catch (error) {
      console.error('Error initializing GA:', error);
    }
    logPageView();
  }, []);

  return (
    <CookieProvider>
      <Router>
        <ScrollToTop />
        <div className="App">
          <Header />
          <CookieConsent />
          <main className="main-content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/results" element={<ResultsPage />} />
              <Route path="/tyre/:id" element={<TyreDetailPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/faq" element={<FaqPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/affiliate-disclosure" element={<AffiliateDisclosurePage />} />
              <Route path="/cookie-policy" element={<CookiePolicyPage />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </CookieProvider>
  );
}

export default App;