import React, { useEffect } from 'react';
import { logPageView, logEvent } from '../config/analytics.js';
import './AboutPage.css';

const AboutPage = () => {
    useEffect(() => {
        logPageView('/about');
    }, []);

    const handleLinkClick = (linkName) => {
        logEvent('About', 'Link Click', linkName);
    };

    return (
        <div className="about-page">
            <div className="container">
                <h1>About UK Tyre Compare</h1>
                <div className="about-content">
                    <section>
                        <h2>Our Mission</h2>
                        <p>At UK Tyre Compare, we simplify the tyre buying process by providing transparent price comparisons from trusted UK retailers. Our goal is to help motorists make informed decisions and find the best deals on quality tyres.</p>
                    </section>
                    
                    <section>
                        <h2>How We Work</h2>
                        <p>We partner directly with leading UK tyre retailers to bring you accurate, up-to-date prices. Our comparison tool helps you find the right tyres for your vehicle while ensuring you get the best value for your money.</p>
                    </section>
                    
                    <section>
                        <h2>Why Choose Us</h2>
                        <ul>
                            <li>Up-to-date price comparisons</li>
                            <li>Direct partnerships with trusted retailers</li>
                            <li>Easy-to-use search tools</li>
                            <li>Comprehensive tyre information</li>
                            <li>Independent and unbiased comparisons</li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default AboutPage;