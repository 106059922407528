import React, { useEffect } from 'react';
import './ContactPage.css';
import { logPageView, logEvent } from '../config/analytics.js';

const ContactPage = () => {
  useEffect(() => {
    logPageView('/contact');
  }, []);

  const handleSubmit = (formData) => {
    logEvent('Contact', 'Form Submit', formData.subject);
  };

  const handleMethodClick = (method) => {
    logEvent('Contact', 'Method Click', method);
  };

  return (
    <div className="contact-page">
      <div className="container">
        <h1>Contact Us</h1>
        <div className="contact-content">
          <p>Have questions about UK Tyre Compare? We're here to help.</p>
          
          <section className="contact-methods">
            <div className="contact-method" onClick={() => handleMethodClick('Email')}>
              <h2>Email</h2>
              <p>support@tyre-compare.co.uk</p>
            </div>
            
            <div className="contact-method" onClick={() => handleMethodClick('Business Hours')}>
              <h2>Business Hours</h2>
              <p>Monday - Friday: 9:00 AM - 5:00 PM GMT</p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;