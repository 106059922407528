import { logEvent } from '../config/analytics.js';

export const searchTyres = async ({ width, profile, rim, speed, maxPrice }) => {
    try {
        logEvent('Search', 'Tyre Search', `${width}/${profile}R${rim}`);

        const params = new URLSearchParams({
            width,
            profile,
            rim,
            ...(speed && { speed }),
            ...(maxPrice && { maxPrice })
        });

        const url = `/api/tyres?${params}`;
        console.log('API Request Debug:', {
            url,
            hasApiKey: !!process.env.REACT_APP_API_KEY,
            apiKeyPresent: process.env.REACT_APP_API_KEY ? 'yes' : 'no'
        });

        const response = await fetch(url, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
            }
        });
        
        if (!response.ok) {
            const text = await response.text();
            console.error('API Error:', {
                status: response.status,
                text,
                url
            });
            throw new Error(`API Error: ${text}`);
        }
        const data = await response.json();
        logEvent('Search Results', 'Results Count', `Count: ${data.length}`);
        return data;
    } catch (error) {
        console.error('Error fetching tyres:', error);
        throw error;
    }
};

export const getTyreById = async (id) => {
    try {
        const response = await fetch(`/api/tyres/${id}`, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            const text = await response.text();
            console.error('API Error:', {
                status: response.status,
                text,
                id
            });
            if (response.status === 404) {
                throw new Error('Product not found');
            }
            throw new Error(`API Error: ${text}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching tyre details:', error);
        throw error;
    }
};