// Utility for managing keyboard navigation and focus trapping
export const createFocusTrap = (containerElement, options = {}) => {
  const {
    initialFocus,
    onEscape,
    autoFocus = true,
    returnFocusOnDeactivate = true
  } = options;

  let previousActiveElement = document.activeElement;
  let firstFocusableElement;
  let lastFocusableElement;

  const getFocusableElements = () => {
    const focusableElements = containerElement.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    return Array.from(focusableElements).filter(
      el => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
    );
  };

  const updateFocusableElements = () => {
    const focusableElements = getFocusableElements();
    firstFocusableElement = focusableElements[0];
    lastFocusableElement = focusableElements[focusableElements.length - 1];
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && onEscape) {
      onEscape();
      return;
    }

    if (e.key === 'Tab') {
      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement?.focus();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          e.preventDefault();
          firstFocusableElement?.focus();
        }
      }
    }
  };

  const activate = () => {
    updateFocusableElements();
    document.addEventListener('keydown', handleKeyDown);
    
    if (autoFocus) {
      if (initialFocus && typeof initialFocus === 'function') {
        initialFocus()?.focus();
      } else if (initialFocus?.focus) {
        initialFocus.focus();
      } else {
        firstFocusableElement?.focus();
      }
    }
  };

  const deactivate = () => {
    document.removeEventListener('keydown', handleKeyDown);
    if (returnFocusOnDeactivate && previousActiveElement?.focus) {
      previousActiveElement.focus();
    }
  };

  return {
    activate,
    deactivate,
    updateFocusableElements
  };
};

// Utility for managing keyboard navigation in lists
export const createListNavigation = (listElement, options = {}) => {
  const {
    onSelect,
    orientation = 'vertical',
    wrap = true
  } = options;

  const getItems = () => {
    return Array.from(listElement.querySelectorAll('[role="option"], [role="menuitem"], [role="listitem"]'));
  };

  const handleKeyDown = (e) => {
    const items = getItems();
    const currentIndex = items.indexOf(document.activeElement);
    
    let nextIndex;
    
    switch (e.key) {
      case 'ArrowDown':
        if (orientation === 'vertical') {
          e.preventDefault();
          nextIndex = currentIndex + 1;
          if (nextIndex >= items.length && wrap) {
            nextIndex = 0;
          }
        }
        break;
      
      case 'ArrowUp':
        if (orientation === 'vertical') {
          e.preventDefault();
          nextIndex = currentIndex - 1;
          if (nextIndex < 0 && wrap) {
            nextIndex = items.length - 1;
          }
        }
        break;
      
      case 'ArrowRight':
        if (orientation === 'horizontal') {
          e.preventDefault();
          nextIndex = currentIndex + 1;
          if (nextIndex >= items.length && wrap) {
            nextIndex = 0;
          }
        }
        break;
      
      case 'ArrowLeft':
        if (orientation === 'horizontal') {
          e.preventDefault();
          nextIndex = currentIndex - 1;
          if (nextIndex < 0 && wrap) {
            nextIndex = items.length - 1;
          }
        }
        break;
      
      case 'Home':
        e.preventDefault();
        nextIndex = 0;
        break;
      
      case 'End':
        e.preventDefault();
        nextIndex = items.length - 1;
        break;
      
      case 'Enter':
      case ' ':
        e.preventDefault();
        if (onSelect && currentIndex >= 0) {
          onSelect(items[currentIndex], currentIndex);
        }
        return;
    }
    
    if (nextIndex !== undefined && nextIndex >= 0 && nextIndex < items.length) {
      items[nextIndex].focus();
    }
  };

  const activate = () => {
    listElement.addEventListener('keydown', handleKeyDown);
  };

  const deactivate = () => {
    listElement.removeEventListener('keydown', handleKeyDown);
  };

  return {
    activate,
    deactivate
  };
};