import React, { useState, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { searchTyres } from '../services/tyreService.js';
import TyreList from '../components/results/TyreList.js';
import FilterPanel from '../components/results/FilterPanel.js';
import './ResultsPage.css';
import ComparisonSidebar from '../components/results/ComparisonSidebar.js';
import { logEvent, logPageView } from '../config/analytics.js';
import { useBreakpoint } from '../utils/breakpoints.js';

function ResultsPage() {
  const [searchParams] = useSearchParams();
  const [tyres, setTyres] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    brand: [],
    maxPrice: null,  // Changed from 500 to null
    season: [],
    fuel: [],
    grip: [],
    noise: []
  });
  const [starredTyres, setStarredTyres] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isCompareOpen, setIsCompareOpen] = useState(false); // Default to collapsed
  const breakpoint = useBreakpoint();

  // Get exact values from URL, null if not present
  const width = searchParams.get('width');
  const profile = searchParams.get('profile');
  const rim = searchParams.get('rim');
  const speed = searchParams.get('speed');

  useEffect(() => {
    const fetchTyres = async () => {
      // Only search if we have all required parameters
      if (!width || !profile || !rim) {
        setTyres([]);
        return;
      }

      setLoading(true);
      try {
        const results = await searchTyres({ width, profile, rim, speed });
        setTyres(results);
      } catch (err) {
        setError('Failed to fetch tyre data. Please try again later.');
        console.error('Search Error:', { err, params: { width, profile, rim, speed }});
      } finally {
        setLoading(false);
      }
    };

    fetchTyres();
  }, [width, profile, rim, speed]);

  useEffect(() => {
    logPageView('/results');
  }, []);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const toggleFilter = (type, value) => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      if (newFilters[type].includes(value)) {
        newFilters[type] = newFilters[type].filter(item => item !== value);
      } else {
        newFilters[type].push(value);
      }
      return newFilters;
    });
  };

  const handleStarClick = (tyre) => {
    try {
      setStarredTyres(prevStarred => {
        const isCurrentlyStarred = prevStarred.some(t => t.id === tyre.id);
        
        if (isCurrentlyStarred) {
          const newStarred = prevStarred.filter(t => t.id !== tyre.id);
          // Close sidebar if removing last tyre
          if (newStarred.length === 0) {
            setIsCompareOpen(false);
          }
          return newStarred;
        }
        
        if (prevStarred.length >= 3) {
          alert('You can only compare up to 3 tyres');
          return prevStarred;
        }
        
        // Auto-open sidebar when adding first tyre
        if (prevStarred.length === 0) {
          setIsCompareOpen(true);
        }
        
        return [...prevStarred, tyre];
      });
    } catch (error) {
      console.error('Error handling star click:', error);
    }
  };

  const removeFromStarred = (tyreId) => {
    setStarredTyres(prev => prev.filter(t => t.id !== tyreId));
  };

  const filteredTyres = tyres.filter(tyre => {
    // Get lowest price from retailers only
    const lowestPrice = Math.min(
      ...tyre.retailers.map(r => r.price)
    );

    // Brand filter
    if (filters.brand.length > 0 && !filters.brand.includes(tyre.brand)) return false;
    
    // Price filter - only apply if maxPrice is not null
    if (filters.maxPrice !== null && lowestPrice > filters.maxPrice) return false;
    
    // Season filter (unchanged)
    if (filters.season.length > 0 && !filters.season.includes(tyre.season)) return false;
    
    // Rating filters - show equal or better ratings
    const ratings = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    const noiseRatings = ['A', 'B', 'C'];  // Separate array for noise ratings
    
    if (filters.fuel.length > 0) {
      const worstAllowedIndex = ratings.indexOf(filters.fuel[filters.fuel.length - 1]);
      const tyreRatingIndex = ratings.indexOf(tyre.fuelEfficiency);
      if (tyreRatingIndex > worstAllowedIndex) return false;
    }
    
    if (filters.grip.length > 0) {
      const worstAllowedIndex = ratings.indexOf(filters.grip[filters.grip.length - 1]);
      const tyreRatingIndex = ratings.indexOf(tyre.wetGrip);
      if (tyreRatingIndex > worstAllowedIndex) return false;
    }
    
    // Noise filter remains dB based
    if (filters.noise.length > 0) {
      const highestAllowedNoise = Math.max(...filters.noise);
      if (tyre.noiseDb > highestAllowedNoise) return false;
    }
    
    // Updated Noise Rating filter
    if (filters.noise.length > 0) {
      const worstAllowedIndex = noiseRatings.indexOf(filters.noise[filters.noise.length - 1]);
      const tyreRatingIndex = noiseRatings.indexOf(tyre.noiseGrade);
      if (tyreRatingIndex > worstAllowedIndex) return false;
    }

    return true;
  });

  const sortedTyres = [...filteredTyres].sort((a, b) => {
    const lowestPriceA = Math.min(...a.retailers.map(r => r.price));
    const lowestPriceB = Math.min(...b.retailers.map(r => r.price));
    return lowestPriceA - lowestPriceB;
  });

  const toggleFilters = () => {
    setIsFilterOpen(!isFilterOpen);
    logEvent('Filters', 'Toggle Mobile Panel', isFilterOpen ? 'Close' : 'Open');
  };

  const toggleCompare = () => {
    setIsCompareOpen(!isCompareOpen);
    logEvent('Compare', 'Toggle Panel', !isCompareOpen ? 'Open' : 'Close');
  };

  return (
    <div className="results-page">
      <div className="container">
        {breakpoint === 'mobile' && (
          <>
            <button 
              className="filter-toggle"
              onClick={toggleFilters}
              aria-label="Toggle filters"
              aria-expanded={isFilterOpen}
            >
              <svg viewBox="0 0 24 24" width="24" height="24">
                <path fill="currentColor" d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z"/>
              </svg>
            </button>
            <button 
              className="compare-toggle"
              onClick={toggleCompare}
              aria-label="Toggle comparison"
              aria-expanded={isCompareOpen}
            >
              <svg viewBox="0 0 24 24" width="24" height="24">
                <path fill="currentColor" d="M9 3L5 6.99h3V14h2V6.99h3L9 3zm7 14.01V10h-2v7.01h-3L15 21l4-3.99h-3z"/>
              </svg>
              <span className="compare-count">{starredTyres.length}</span>
            </button>
            <div 
              className={`panel-overlay ${isFilterOpen || isCompareOpen ? 'active' : ''}`}
              onClick={() => {
                setIsFilterOpen(false);
                setIsCompareOpen(false);
              }}
            />
          </>
        )}
        <h1>Tyre Search Results</h1>
        <p className="search-summary">
          Showing tyres size: <strong>{width}/{profile}R{rim}{speed ? ` ${speed}` : ''}</strong>
        </p>
        
        <div className="results-page-container">
          <div className="results-main">
            <div className="row">
              <div className={`col-md-3 ${breakpoint === 'mobile' ? 'mobile-filter-container' : ''} ${isFilterOpen ? 'active' : ''}`}>
                <FilterPanel 
                  filters={filters} 
                  onFilterChange={handleFilterChange} 
                  tyres={tyres}
                  toggleFilter={toggleFilter}
                  isMobile={breakpoint === 'mobile'}
                  onClose={() => setIsFilterOpen(false)}
                />
              </div>
              
              <div className="col-md-9 main-content">
                {loading ? (
                  <div className="loading-spinner">Loading tyre prices...</div>
                ) : error ? (
                  <div className="alert alert-danger">{error}</div>
                ) : sortedTyres.length === 0 ? (
                  <div className="no-results">
                    <h3>No tyres found</h3>
                    <p>Try adjusting your search criteria or filters</p>
                  </div>
                ) : (
                  <TyreList 
                    tyres={sortedTyres} 
                    starredTyres={starredTyres}
                    onStarTyre={handleStarClick}  // Match the prop name in TyreList
                  />
                )}
              </div>
            </div>
          </div>
          <ComparisonSidebar 
            starredTyres={starredTyres} 
            removeFromStarred={removeFromStarred}
            isExpanded={isCompareOpen}  // Changed from isOpen to match component prop
            onClose={toggleCompare}     // Use the toggle handler
            isMobile={breakpoint === 'mobile'}
          />
        </div>
      </div>
    </div>
  );
}

export default ResultsPage;