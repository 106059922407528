import ReactGA from 'react-ga4';

let isInitialized = false;

export const initGA = () => {
    try {
        if (!isInitialized && process.env.REACT_APP_GA_ID) {
            ReactGA.initialize(process.env.REACT_APP_GA_ID);
            isInitialized = true;
            console.log('GA initialized successfully');
        }
    } catch (error) {
        console.error('GA initialization failed:', error);
    }
};

export const logPageView = (path = window.location.pathname) => {
    try {
        if (isInitialized) {
            ReactGA.send('page_view', { page_path: path });
        }
    } catch (error) {
        console.error('Error logging page view:', error);
    }
};

export const logEvent = (category, action, label) => {
    try {
        if (isInitialized) {
            ReactGA.event({
                category,
                action,
                label
            });
        }
    } catch (error) {
        console.error('Error logging event:', error);
    }
};