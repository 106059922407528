import React from 'react';
import './AffiliateDisclosurePage.css';

function AffiliateDisclosurePage() {
  return (
    <div className="affiliate-disclosure-page">
      <div className="container">
        <h1>Affiliate Disclosure</h1>
        <div className="affiliate-content">
          <section>
            <h2>Our Partnerships</h2>
            <p>UK Tyre Compare maintains direct partnerships with tyre retailers. When you purchase through links on our site, we may earn a commission. This helps support our service while ensuring you get the best available prices.</p>
          </section>

          <section>
            <h2>How It Works</h2>
            <p>Our comparison service is free to use. We earn commissions from retailers when:</p>
            <ul>
              <li>You click through to a retailer's website</li>
              <li>You complete a purchase with our retail partners</li>
            </ul>
          </section>

          <section>
            <h2>Our Commitment</h2>
            <p>These partnerships do not influence our price comparisons or recommendations. We display all available options regardless of commission arrangements.</p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default AffiliateDisclosurePage;