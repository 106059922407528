import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './TyreSearchForm.css';
import { logEvent } from '../../config/analytics.js';

function TyreSearchForm() {
  const [tyreSize, setTyreSize] = useState({
    width: '',
    profile: '',
    rimSize: '',
    speedRating: ''
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    logEvent('Search', 'Field Change', `${name}: ${value}`);
    setTyreSize(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const searchString = `${tyreSize.width}/${tyreSize.profile}R${tyreSize.rimSize}`;
    logEvent('Search', 'Submit', searchString);
    navigate(`/results?width=${tyreSize.width}&profile=${tyreSize.profile}&rim=${tyreSize.rimSize}&speed=${tyreSize.speedRating}`);
  };

  return (
    <div className="tyre-search-form">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-3 mb-3">
            <label htmlFor="width">Width (mm)</label>
            <input
              type="number"
              className="form-control"
              id="width"
              name="width"
              placeholder="e.g. 225"
              value={tyreSize.width}
              onChange={handleChange}
              required
            />
          </div>
          
          <div className="col-md-3 mb-3">
            <label htmlFor="profile">Profile (%)</label>
            <input
              type="number"
              className="form-control"
              id="profile"
              name="profile"
              placeholder="e.g. 45"
              value={tyreSize.profile}
              onChange={handleChange}
              required
            />
          </div>
          
          <div className="col-md-3 mb-3">
            <label htmlFor="rimSize">Rim Size (inches)</label>
            <input
              type="number"
              className="form-control"
              id="rimSize"
              name="rimSize"
              placeholder="e.g. 17"
              value={tyreSize.rimSize}
              onChange={handleChange}
              required
            />
          </div>
          
          <div className="col-md-3 mb-3">
            <label htmlFor="speedRating">Speed Rating (optional)</label>
            <select
              className="form-control"
              id="speedRating"
              name="speedRating"
              value={tyreSize.speedRating}
              onChange={handleChange}
            >
              <option value="">Any</option>
              <option value="L">L (75 mph)</option>
              <option value="M">M (81 mph)</option>
              <option value="N">N (87 mph)</option>
              <option value="P">P (93 mph)</option>
              <option value="Q">Q (99 mph)</option>
              <option value="R">R (106 mph)</option>
              <option value="S">S (112 mph)</option>
              <option value="T">T (118 mph)</option>
              <option value="U">U (124 mph)</option>
              <option value="H">H (130 mph)</option>
              <option value="V">V (149 mph)</option>
              <option value="W">W (168 mph)</option>
              <option value="Y">Y (186 mph)</option>
              <option value="Z">Z (150+ mph)</option>
            </select>
          </div>
        </div>
        
        <button type="submit" className="btn btn-primary btn-lg btn-block">Compare Tyre Prices</button>
      </form>
    </div>
  );
}

export default TyreSearchForm;