import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import { useBreakpoint } from '../../utils/breakpoints.js';
import './Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const breakpoint = useBreakpoint();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="site-header" role="banner">
      <div className="container">
        <div className="header-content">
          <Link to="/" className="logo" aria-label="UK Tyre Compare Home">
            <img src={logo} alt="UK Tyre Compare Logo" className="logo-icon" />
            <h1>UK Tyre Compare</h1>
          </Link>
          
          <button 
            className={`menu-button ${isMenuOpen ? 'active' : ''}`}
            onClick={toggleMenu}
            aria-label="Toggle navigation menu"
            aria-expanded={isMenuOpen}
            aria-controls="main-navigation"
          >
            <svg viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
            </svg>
          </button>

          <nav 
            className={`main-nav ${isMenuOpen ? 'active' : ''}`}
            role="navigation"
            aria-label="Main navigation"
            id="main-navigation"
          >
            <ul className="nav-list" role="menubar">
              <li role="none"><Link to="/" onClick={() => setIsMenuOpen(false)} role="menuitem">Home</Link></li>
              <li role="none"><Link to="/about" onClick={() => setIsMenuOpen(false)} role="menuitem">About</Link></li>
              <li role="none"><Link to="/faq" onClick={() => setIsMenuOpen(false)} role="menuitem">FAQ</Link></li>
              <li role="none"><Link to="/contact" onClick={() => setIsMenuOpen(false)} role="menuitem">Contact</Link></li>
              {breakpoint === 'mobile' && (
                <>
                  <li role="none"><Link to="/privacy" onClick={() => setIsMenuOpen(false)} role="menuitem">Privacy Policy</Link></li>
                  <li role="none"><Link to="/terms" onClick={() => setIsMenuOpen(false)} role="menuitem">Terms of Use</Link></li>
                  <li role="none"><Link to="/cookie-policy" onClick={() => setIsMenuOpen(false)} role="menuitem">Cookie Policy</Link></li>
                  <li role="none"><Link to="/affiliate-disclosure" onClick={() => setIsMenuOpen(false)} role="menuitem">Affiliate Disclosure</Link></li>
                </>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;