import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CookieContext } from '../../context/CookieContext.js';
import './CookieConsent.css';

function CookieConsent() {
  const [showConsent, setShowConsent] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const { cookiePreferences, updatePreferences } = useContext(CookieContext);

  useEffect(() => {
    const consent = localStorage.getItem('cookiePreferences');
    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  const handleAcceptAll = () => {
    updatePreferences({
      essential: true,
      analytics: true,
      marketing: true
    });
    setShowConsent(false);
  };

  const handleSavePreferences = () => {
    updatePreferences(cookiePreferences);
    setShowConsent(false);
  };

  if (!showConsent) return null;

  return (
    <div className="cookie-consent">
      <div className="container">
        <div className="cookie-content">
          <div className="cookie-header">
            <h3>Cookie Preferences</h3>
            <p>Select which cookies you want to accept.</p>
          </div>

          {showDetails && (
            <div className="cookie-options">
              <div className="cookie-option">
                <label>
                  <input
                    type="checkbox"
                    checked={cookiePreferences.essential}
                    disabled
                  />
                  Essential Cookies (Required)
                </label>
                <p>Necessary for the website to function properly</p>
              </div>

              <div className="cookie-option">
                <label>
                  <input
                    type="checkbox"
                    checked={cookiePreferences.analytics}
                    onChange={(e) => 
                      updatePreferences({
                        ...cookiePreferences,
                        analytics: e.target.checked
                      })
                    }
                  />
                  Analytics Cookies
                </label>
                <p>Help us understand how visitors interact with our website</p>
              </div>

              <div className="cookie-option">
                <label>
                  <input
                    type="checkbox"
                    checked={cookiePreferences.marketing}
                    onChange={(e) => 
                      updatePreferences({
                        ...cookiePreferences,
                        marketing: e.target.checked
                      })
                    }
                  />
                  Advertising Cookies
                </label>
                <p>Used to show relevant advertisements and measure their performance</p>
              </div>
            </div>
          )}

          <div className="cookie-actions">
            {!showDetails ? (
              <>
                <button onClick={handleAcceptAll} className="btn btn-primary">
                  Accept All
                </button>
                <button 
                  onClick={() => setShowDetails(true)} 
                  className="btn btn-outline-light"
                >
                  Customize
                </button>
              </>
            ) : (
              <button 
                onClick={handleSavePreferences} 
                className="btn btn-primary"
              >
                Save Preferences
              </button>
            )}
          </div>

          <div className="cookie-footer">
            <Link to="/cookie-policy">Cookie Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookieConsent;