import React, { createContext, useState, useEffect } from 'react';

export const CookieContext = createContext();

export function CookieProvider({ children }) {
  const [cookiePreferences, setCookiePreferences] = useState({
    essential: true, // Always true, can't be disabled
    analytics: false,
    marketing: false
  });

  useEffect(() => {
    const savedPreferences = localStorage.getItem('cookiePreferences');
    if (savedPreferences) {
      const preferences = JSON.parse(savedPreferences);
      setCookiePreferences(preferences);
      updateGoogleConsent(preferences);
    }
  }, []);

  const updateGoogleConsent = (preferences) => {
    if (window.gtag) {
      window.gtag('consent', 'update', {
        'ad_storage': preferences.marketing ? 'granted' : 'denied',
        'analytics_storage': preferences.analytics ? 'granted' : 'denied'
      });
    }
  };

  const updatePreferences = (preferences) => {
    setCookiePreferences(preferences);
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    updateGoogleConsent(preferences);
  };

  return (
    <CookieContext.Provider value={{ cookiePreferences, updatePreferences }}>
      {children}
    </CookieContext.Provider>
  );
}