import React from 'react';

// Use our existing Tailwind breakpoints
const BREAKPOINTS = {
  mobile: 640,  // sm
  tablet: 768,  // md
  desktop: 1024 // lg
};

// Utility hooks that work alongside our existing styling
export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = React.useState(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth <= BREAKPOINTS.mobile) return 'mobile';
      if (window.innerWidth <= BREAKPOINTS.tablet) return 'tablet';
      return 'desktop';
    }
    return 'desktop';
  });

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= BREAKPOINTS.mobile) setBreakpoint('mobile');
      else if (window.innerWidth <= BREAKPOINTS.tablet) setBreakpoint('tablet');
      else setBreakpoint('desktop');
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpoint;
};

// Simple boolean checks for conditional logic
export const isMobile = () => typeof window !== 'undefined' && window.innerWidth <= BREAKPOINTS.mobile;
export const isTablet = () => typeof window !== 'undefined' && window.innerWidth <= BREAKPOINTS.tablet && window.innerWidth > BREAKPOINTS.mobile;
export const isDesktop = () => typeof window !== 'undefined' && window.innerWidth > BREAKPOINTS.tablet;