import React, { useEffect } from 'react';
import './FaqPage.css';
import { logPageView, logEvent } from '../config/analytics.js';

const FaqPage = () => {
  useEffect(() => {
    logPageView('/faq');
  }, []);

  const handleQuestionClick = (question) => {
    logEvent('FAQ', 'Question View', question);
  };

  const handleSearchQuery = (query) => {
    logEvent('FAQ', 'Search', query);
  };

  return (
    <div className="faq-page">
      <div className="container">
        <h1>Frequently Asked Questions</h1>
        <div className="faq-content">
          <section className="faq-item" onClick={() => handleQuestionClick('How does UK Tyre Compare work?')}>
            <h2>How does UK Tyre Compare work?</h2>
            <p>We aggregate prices from trusted UK tyre retailers and present them in an easy-to-compare format. Simply enter your tyre size, and we'll show you available options with current prices.</p>
          </section>

          <section className="faq-item" onClick={() => handleQuestionClick('How do I find my tyre size?')}>
            <h2>How do I find my tyre size?</h2>
            <p>You can find your tyre size in three ways:</p>
            <ul>
              <li>Check your current tyre sidewall for numbers like "205/55R16"</li>
              <li>Look in your vehicle's manual</li>
              <li>Check the sticker inside your driver's door frame</li>
            </ul>
          </section>

          <section className="faq-item" onClick={() => handleQuestionClick('Are the prices up to date?')}>
            <h2>Are the prices up to date?</h2>
            <p>Yes, we regularly update prices through our direct retailer partnerships. However, final prices may vary slightly at checkout due to promotions or stock changes.</p>
          </section>

          <section className="faq-item" onClick={() => handleQuestionClick('Can I buy tyres directly through UK Tyre Compare?')}>
            <h2>Can I buy tyres directly through UK Tyre Compare?</h2>
            <p>We don't sell tyres directly. Instead, we connect you with trusted retailers where you can complete your purchase. This ensures you get genuine products with full manufacturer warranties.</p>
          </section>

          <section className="faq-item" onClick={() => handleQuestionClick('What information do you show about each tyre?')}>
            <h2>What information do you show about each tyre?</h2>
            <p>For each tyre, we display:</p>
            <ul>
              <li>Brand and model</li>
              <li>Current prices from different retailers</li>
              <li>EU tyre label ratings (fuel efficiency, wet grip, noise)</li>
              <li>Season type (summer, winter, all-season)</li>
              <li>Available sizes</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}

export default FaqPage;