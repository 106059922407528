import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { getTyreById } from '../services/tyreService.js';
import './TyreDetailPage.css';
import { logEvent, logPageView } from '../config/analytics.js';

function TyreDetailPage() {
  const { id } = useParams();
  const [tyre, setTyre] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTyre = async () => {
      setLoading(true);
      try {
        const result = await getTyreById(id);
        console.log('API Response:', result); // Debug log
        
        // Transform data to match existing components
        const formattedTyre = {
          id: result._id,
          brand: result.brand,
          model: result.model,
          size: `${result.size.width}/${result.size.profile}R${result.size.rim}`,
          speed: result.speed.rating,
          season: result.season,
          fuelEfficiency: result.ratings.fuel,
          wetGrip: result.ratings.grip,
          noiseGrade: result.ratings.noise.grade,  // Changed from noiseLevel to noiseGrade
          noiseDb: result.ratings.noise.db,
          retailers: result.retailers || [],
          image: result.image
        };
        
        console.log('Formatted Tyre:', formattedTyre); // Debug log
        setTyre(formattedTyre);
      } catch (err) {
        setError('Failed to fetch tyre data. Please try again later.');
        console.error('Detail Page Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchTyre();
  }, [id]);

  useEffect(() => {
    logPageView('/tyre-details');
    if (tyre) {
      logEvent('view_item', {
        currency: 'GBP',
        items: [{
          item_id: tyre.id,
          item_name: tyre.model,
          item_brand: tyre.brand,
          item_category: 'Tyres',
          item_variant: `${tyre.size}`,
          price: tyre.retailers.length > 0 ? tyre.retailers[0].price : 0
        }]
      });
    }
  }, [tyre]);

  // Generate affiliate link
  const generateLink = (url, retailer) => {
    // In a real app, you would add your affiliate codes here
    return url;
  };

  const handleRetailerClick = (retailer, price) => {
    logEvent('ecommerce', 'begin_checkout', {
      currency: 'GBP',
      value: price,
      items: [{
        item_id: tyre.id,
        item_name: tyre.model,
        item_brand: tyre.brand,
        item_category: 'Tyres',
        item_variant: `${tyre.size}`,
        price: price,
        quantity: 1
      }],
      retailer_name: retailer.name,
      retailer_id: retailer.id
    });
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1); // This mimics browser's back button
  };

  if (loading) return <div className="loading-spinner">Loading tyre details...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;
  if (!tyre) return <div className="alert alert-warning">Tyre not found</div>;

  // Sort retailers by price (lowest first)
  const sortedRetailers = tyre.retailers 
    ? [...tyre.retailers].sort((a, b) => a.price - b.price)
    : [];

  // Create URL with current search params
  const backToResultsUrl = {
    pathname: '/results',
    search: location.search || searchParams.toString()
  };

  // Log the search parameters for debugging
  console.log('Navigation Debug:', {
    currentSearch: location.search,
    searchParams: Object.fromEntries(searchParams.entries()),
    backUrl: backToResultsUrl
  });

  return (
    <div className="tyre-detail-page">
      <div className="container">
        <div className="back-link">
          <a 
            href="#"
            onClick={handleBackClick}
            className="btn btn-link"
          >
            &larr; Back to results
          </a>
        </div>
        
        <div className="tyre-header">
          <div>
            <h1>{tyre.brand} {tyre.model}</h1>
            <p className="tyre-specs">
              Size: {tyre.size} {tyre.speed} • Type: {tyre.season}
            </p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-md-4">
            <div className="tyre-image-container">
              <img 
                src={tyre.image || 'https://postimg.cc/mzT5ZsD4'} 
                alt={`${tyre.brand} ${tyre.model}`}
                className="tyre-detail-image"
                onError={(e) => {
                  e.target.src = 'https://postimg.cc/mzT5ZsD4';
                }}
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="tyre-ratings-detailed">
              <div className="rating-item">
                <h3>Fuel Efficiency</h3>
                <span className={`rating-grade grade-${tyre.fuelEfficiency}`}>{tyre.fuelEfficiency}</span>
                <span className="noise-db invisible">spacer</span>
                <p>How efficiently the tyre uses fuel</p>
              </div>
              
              <div className="rating-item">
                <h3>Wet Grip</h3>
                <span className={`rating-grade grade-${tyre.wetGrip}`}>{tyre.wetGrip}</span>
                <span className="noise-db invisible">spacer</span>
                <p>How well the tyre performs in wet conditions</p>
              </div>
              
              <div className="rating-item">
                <h3>Noise Level</h3>
                <span className={`rating-grade grade-${tyre.noiseGrade}`}>{tyre.noiseGrade}</span>
                <span className="noise-db">{tyre.noiseDb} dB</span>
                <p>The external rolling noise of the tyre</p>
              </div>
            </div>
          </div>
        </div>
        
        <h2>All Available Deals</h2>
        <p className="price-disclaimer">
            Prices shown were last updated from retailers on {new Date().toLocaleDateString()}. 
            Current offers may vary depending on retailer promotions.
        </p>
        <div className="all-deals">
          <table className="table">
            <thead>
              <tr>
                <th>Retailer</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {sortedRetailers.map((retailer, index) => (
                <tr key={retailer.name} className={index === 0 ? 'best-price-row' : ''}>
                  <td>
                    {retailer.name}
                    {index === 0 && <span className="best-price-label">Best Price</span>}
                  </td>
                  <td className="price-cell">£{retailer.price.toFixed(2)}</td>
                  <td>
                    <a 
                      href={retailer.url} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="btn btn-success btn-sm"
                      onClick={() => handleRetailerClick(retailer, retailer.price)}
                    >
                      View Deal
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default TyreDetailPage;